<template>
    <r-e-dialog title="发布商铺" class="layer-release-shops" :visible.sync="dialogVisible" show-footer top="2vh"
                @click-submit="handleSubmitHouse" @click-cancel="handleCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="商铺名称" prop="name">
                <el-input v-model="formPublish.name" placeholder="请输入商铺名称"/>
            </el-form-item>
            <el-form-item label="商铺编号" prop="apartmentNo">
                <el-input v-model="formPublish.apartmentNo" placeholder="请输入商铺编号"/>
            </el-form-item>
            <div class="flex">
                <el-form-item label="所属区域" prop="province">
                    <el-select v-model="formPublish.province" disabled placeholder="请选择省" @change="provinceChange">
                        <el-option v-for="(item,index) in provinceList" :key="index" :label="item.name"
                                   :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="city">
                    <el-select v-model="formPublish.city" disabled placeholder="请选择市" @change="cityChange">
                        <el-option v-for="(item,index) in cityList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="10px" prop="district">
                    <el-select v-model="formPublish.district" disabled placeholder="请选择区">
                        <el-option v-for="(item,index) in areaList" :key="index" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
            </div>
            <el-form-item label="所在商圈" prop="communtiyName">
                <el-input v-model="formPublish.communtiyName" disabled placeholder="请输入所在商圈"/>
            </el-form-item>
            <el-form-item label="所处楼层" prop="floorNum">
                <el-input-number v-model="formPublish.floorNum" :min="1" :max="34" style="width: 215px;"
                                 placeholder="请输入所处楼层"/>
            </el-form-item>
            <el-form-item label="房源标题" prop="apartmentPublishName">
                <el-input v-model="formPublish.apartmentPublishName" placeholder="请输入房源标题"/>
            </el-form-item>
            <el-form-item label="商铺租金" prop="amount">
                <el-input-number v-model="formPublish.amount" :precision="2" placeholder="请输入商铺租金"
                                 style="width: 215px;"/>
            </el-form-item>
            <el-form-item label="建筑面积" prop="outArea">
                <el-input v-model="formPublish.outArea" placeholder="请输入建筑面积">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <el-form-item label="使用面积" prop="useArea">
                <el-input v-model="formPublish.useArea" placeholder="请输入使用面积">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <div class="flex">
                <el-form-item label="商铺标签" prop="shopsTag" style="width: 100%;">
                    <div class="tag-group">
                        <el-tag @click="clickAllShopsTag" type="info">全选</el-tag>
                        <el-tag @click="clickShopsTag(item)" v-for="item in shopsTagList" :key="item.code"
                                :type="tagType(item)" effect="plain">
                            {{ item.codeName }}
                        </el-tag>
                    </div>
                </el-form-item>
            </div>
            <el-form-item label="商铺描述" prop="storeDescription">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5}" v-model="formPublish.storeDescription"
                          placeholder="请输入商铺描述"/>
            </el-form-item>
            <el-form-item label="商铺照片">
                <upload-picture-card :uuidList="imgList" :limit="24" @on-success="handleSuccess"
                                     @on-remove="handleRemove"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import uploadPictureCard from "@/components/Upload/upload-picture-card";
    import cityCode from "@/assets/json/city_code.json";
    import {shopPublish} from "@/api/shops-lease";
    import {MessageSuccess, MessageWarning, MessageError} from "@custom/message";
    import {timeFormat, numberFormat, ParseFloatFormat,ParseIntFormat} from "@custom/index";
    export default {
        name: "layer-release-shops",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    name: null,
                    apartmentNo: null,
                    province: null,
                    city: null,
                    district: null,
                    communtiyName: null,
                    floorNum: 1,
                    apartmentPublishName: null,
                    paymentMethod: null,
                    amount: null,
                    outArea: null,
                    useArea: null,
                    shopsTag: [],
                    storeDescription: null,
                },
                rules: {
                    name: [{required: true, message: '请输入商铺名称', trigger: 'blur'}],
                    apartmentNo: [{required: true, message: '请输入商铺编号', trigger: 'blur'}],
                    province: [{required: true, message: '请选择省', trigger: 'change'}],
                    city: [{required: true, message: '请选择市', trigger: 'change'}],
                    district: [{required: true, message: '请选择区', trigger: 'change'}],
                    communtiyName: [{required: true, message: '请输入所在商圈', trigger: 'blur'}],
                    floorNum: [{required: true, message: '请输入所处楼层', trigger: 'blur'}],
                    apartmentPublishName: [{required: true, message: '请输入房源标题', trigger: 'blur'}],
                    paymentMethod: [{required: true, message: '请选择支付方式', trigger: 'change'}],
                    amount: [{required: true, message: '请输入商铺租金', trigger: 'blur'}],
                    outArea: [{required: true, message: '请输入建筑面积', trigger: 'blur'}],
                    useArea: [{required: true, message: '请输入使用面积', trigger: 'blur'}],
                    storeDescription: [{required: true, message: '请输入商铺描述', trigger: 'blur'}],
                },
                provinceList: cityCode,
                cityList: [],
                areaList: [],
                imgList: [],
                shopsTagList: [],
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components: {uploadPictureCard},
        async mounted() {
            this.shopsTagList = await this.$store.dispatch('app/getDictionaryByCodeActions', '100100');
        },
        methods: {
            openDialog(data) {
                let oldFormPublish= this.formPublish;
                let {apartmentNo, area, city, communtiyName, district, emptyDays, floorNum, houseStatus, leasor, name,
                outArea, province, publishName, publishStatus, shopAddress, status, useArea, uuid} = data;
                this.formPublish = {...oldFormPublish,apartmentNo, area, city, communtiyName, district, emptyDays, floorNum, houseStatus, leasor, name,
                    outArea, province, publishName, publishStatus, shopAddress, status, useArea, apartmentUuid:uuid};
                this.dialogVisible = true;
            },
            handleSubmitHouse() {
                let that = this;
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let data = that.formPublish;
                        let {shopsTag: apartmentTagList,amount} = data;
                        let urls = that.imgList.join(";");
                        let loadingOptions = that.loadingOptions;
                        amount = ParseIntFormat(amount);
                        const loading = that.$loading({...loadingOptions});
                        shopPublish({...data,urls,apartmentTagList,amount}).then(res => {
                            MessageSuccess('发布成功');
                            that.handleCancel();
                            loading.close();
                        }).catch(err => loading.close());
                    }
                });
            },
            handleCancel() {
                this.dialogVisible = false;
                this.formPublish = {
                    name: null,
                    apartmentNo: null,
                    province: null,
                    city: null,
                    district: null,
                    communtiyName: null,
                    floorNum: 1,
                    apartmentPublishName: null,
                    paymentMethod: null,
                    amount: null,
                    outArea: null,
                    useArea: null,
                    shopsTag: [],
                    storeDescription: null,
                };
                this.imgList = [];
            },

            handleSuccess({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else {
                        return name;
                    }
                });
            },

            handleRemove({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else {
                        return name;
                    }
                });
            },
            provinceChange(value) {
                const {city, code, name} = this.provinceList.find(i => i.name === value);
                this.formPublish.city = null;
                this.formPublish.district = null;
                this.cityList = city;
                this.areaList = [];
            },
            cityChange(value) {
                const {area, code, name} = this.cityList.find(i => i.name === value);
                this.formPublish.district = null;
                this.areaList = area;
            },
            tagType(item) {
                let {shopsTag} = this.formPublish;
                if (shopsTag.length === 0) return 'info';
                else return shopsTag.some(i => i === item.code) ? '' : 'info'
            },
            clickAllShopsTag() {
                let {shopsTag} = this.formPublish;
                let shopsTagList = this.shopsTagList.map(i => i.code);
                if (shopsTag.length === shopsTagList.length) this.formPublish.shopsTag = []
                else this.formPublish.shopsTag = shopsTagList;
            },
            clickShopsTag(item) {
                let {shopsTag} = this.formPublish;
                let {code} = item;
                if (shopsTag.some(i => i === code)) this.formPublish.shopsTag = shopsTag.filter(i => i !== code);
                else {
                    shopsTag.push(code);
                    this.formPublish.shopsTag = shopsTag;
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    .layer-release-shops {
        .el-input-number, .el-select {
            width: 100%;
        }
    }

    .flex {
        display: flex;

        /deep/ .tag-group {
            white-space: normal;

            .el-tag {
                margin: 0 VW(5px) VH(5px) 0;
                cursor: pointer;
            }
        }

    }
</style>